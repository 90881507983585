import React, { Component } from "react";
import { Link } from 'react-router-dom';
import loadingImg from '../tlh-loading.png';
import Facebook from '../facebook.svg';
import Homelink from '../components/Nav';
import Google from '../google.svg';
//import { isJwt, isTokenValid } from './utilitis/auth.js';
//import VerificationForm from '../components/verificationForm';



export class HomePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            test : '',
            userName : "",
            clientId : "",
            loginMethod: "",
            emailOption: "url",
            checkParam : false,           
            error: false,  
            hide: false, 
            allhide: false,     
            apikey : "",            
            companyName : "",
            companyLogo: "",            
            verifyCode : [],
            errorMessage : "",
            successMessage: "",
            clientState: "",  
            loading: false, 
            clickCount : 0, 
            linkUrl : "",        
            flag : "",
            apiCalled: false , 
            codes : ['', '', '', '', ''],           
     
        }
        this.inputRefs = Array.from({ length: 5 }, () => React.createRef());     
    }    

    getParams = (key) => {
        const params = new URLSearchParams(document.location.search);
        if(params) {
          return params.get(key);
        }
    }

    checkIsBase64(data) {
        const regex = /^[A-Za-z0-9+/=]+$/;
        return regex.test(data);
    } 

    decodeBase64 = (encodedData) => {
        const decodedString = atob(encodedData);
        return JSON.parse(decodedString);
    }

    formatPhoneNumber = (phoneNumber) => {
        // Get last two digits
        var lastTwoDigits = phoneNumber.slice(-2);  
        // Split the string into pairs of two digits
        var splitnumber = phoneNumber.match(/.{1,2}/g).join(' ');      
        // Replace digits with asterisks except the last two
        var formattedNumber = splitnumber.slice(0, -2).replace(/\d/g, '*') + lastTwoDigits;
        
        return formattedNumber;
    }
    formatEmail = (email) => {
        // Split email address into username and domain parts
        var parts = email.split('@');
        if (parts.length !== 2) {
            return "Invalid email address";
        }    
        // Hide characters in the username, leaving only the first three characters visible
        var hiddenUsername = parts[0].substring(0, 3) + '*'.repeat(parts[0].length - 3);
    
        // Return the hidden email address with a placeholder domain
        return hiddenUsername + '@' + parts[1];
    }

    onValueChange = (e) => {             
        this.setState({ emailOption: e.target.value }); 
    }

    changeuserName = async (e) => {
        this.setState({ userName: e.target.value.split(' ').join('') });
    }

    handleInputChange = (index, e) => {
        const { value } = e.target;
        const newInputs = [...this.state.codes];
        newInputs[index] = value;
        this.setState({ codes: newInputs });       
        // Focus on the next input field if available
        if (value !== '' && index < this.state.codes.length - 1 && this.inputRefs[index + 1].current) {
            this.inputRefs[index + 1].current.focus();
        }
    };

    handlePaste = (e) => {
        e.preventDefault();
        const clipboardData = e.clipboardData.getData('Text');
        const pastedValues = clipboardData.slice(0, this.state.codes.length).split(''); // Assuming only 'inputs.length' characters will be pasted
        const newInputs = [...this.state.codes];
        pastedValues.forEach((value, index) => {
        newInputs[index] = value;
        // Focus on the next input field if available
        if (index < this.state.codes.length - 1 && this.inputRefs[index + 1].current) {
            this.inputRefs[index + 1].current.focus();
        }
        });
        this.setState({ codes: newInputs });
    };

    handleKeyDown = (index, e) => {
        if (e.key === 'Backspace' && index > 0 && this.state.codes[index] === '') {
          // If backspace is pressed and the current field is empty, focus on the previous field
          e.preventDefault();
          this.inputRefs[index - 1].current.focus();
        }
    };
   
    // changeverifyCode = async (e) => {
    //     this.setState({ verifyCode: e.target.value });
    // }
    
    
    handleloginSubmit = async (e) => {
        e.preventDefault(); 
        this.setState({loading: true});
        var loginmethod = this.state.loginMethod;
        var url = '';
        var postdata = '';
        const pageurl = new URL(window.location.href);  
        const domainName = pageurl.protocol + '//' + pageurl.hostname;
        try {
            if(loginmethod === 'sms') {
                postdata = JSON.stringify({
                    mobile: this.state.userName,                
                });
                url = 'https://dev-api.theloginhub.eu/api/v1/sms_login';
            } 
            if(loginmethod === 'email') {
                postdata = JSON.stringify({
                    email: this.state.userName,  
                    method: this.state.emailOption,
                    refer_url: domainName,
                    redirect_uri : this.state.linkUrl,
                    return_code: this.state.clientState   
                });
                url = 'https://dev-api.theloginhub.eu/api/v1/email-verification';
            }
            // console.log(url);
            // console.log(postdata);

            let res = await fetch(url, {
                method: "POST",
                headers: {                   
                    'Content-Type': 'application/json',
                    'apikey' : this.state.apikey,
                },
                body: postdata,
            });
            let resJson = await res.json();
            if (resJson.error === true) {
                if(resJson.data && Array.isArray(resJson.data)) {                    
                    var errorObj = [];
                    resJson.data.forEach(element => {
                        errorObj[element.field] = (errorObj[element.field])? errorObj[element.field] + ', ' + element.message: element.message
                    });
                    this.setState({ errorMessage: errorObj,loading:false});
                } else {
                    this.setState({ errorMessage: resJson.data.message, loading:false});
                }
            } 
            if (resJson.success === true) {
                let msg = resJson.data.message;
                //let tel = this.formatPhoneNumber(this.state.userName);
                //let email = this.formatEmail(this.state.userName);
                if(loginmethod === 'sms') {
                    //msg = 'We have sent a verification code to your phone. **'+ tel;
                    msg = msg;
                }
                if(loginmethod === 'email' && this.state.emailOption === 'code') {
                    msg = msg;
                }                
                if(this.state.loginMethod === 'email' && this.state.emailOption === 'url') {
                    msg = msg;
                    this.setState({  allhide: true});                                   
                }
                this.setState({ successMessage: msg ,  errorMessage: "", flag: "true", loading:false}); 
                 
            }
            
            
        } catch (err) {
            this.setState({loading:false});
            console.log(err);           

        }
    } 

    handlecodeSubmit = async (e) => {
        this.setState({loading: true});
        e.preventDefault(); 
        var loginmethod = this.state.loginMethod;
        var apiurl = '';
        var val = '';
        var code = this.state.codes.join('');
       // console.log(this.state.codes.join(''));
        try {
            if(loginmethod === 'sms') {
                val = JSON.stringify({
                    mobile: this.state.userName,
                    code : code
                }); 
                apiurl = 'https://dev-api.theloginhub.eu/api/v1/sms_login/activated';
            } 
            if(loginmethod === 'email') {
                val = JSON.stringify({
                    email: this.state.userName,
                    code : code
                }); 
                apiurl = 'https://dev-api.theloginhub.eu/api/v1/otp-email';
            }
                   
            let res = await fetch(apiurl, {
                method: "POST",
                headers: {                   
                    'Content-Type': 'application/json',                  
                },
                body: val,
            });
            let resData = await res.json();            
            if (resData.error === true) {                                     
                this.setState({ successMessage: "", loading:false});  
                this.setState({ clickCount: this.state.clickCount + 1 });
                console.log(this.state.clickCount);
                if (this.state.clickCount < 3) {                       
                    this.setState({ errorMessage: resData.data + " " + (3 - this.state.clickCount) + " attempt left"});
                }
                if (this.state.clickCount > 2) {
                    this.setState({ errorMessage: resData.data + " Verification attempt exceeded!" });

                }              
                             
            } 
            if (resData.status === true) {                
                var returnparam = '';
                if(resData.data.token) {
                    returnparam = '&token=' + resData.data.token;
                }
                if(resData.data.user) {
                    returnparam = '&user=' + resData.data.user; 
                }
                this.setState({ successMessage: resData.data.message,  errorMessage: "", loading: false, hide:true}); 

                if(this.state.linkUrl) {
                    let customfield = (this.state.clientState) ? '&return_code=' + this.state.clientState : '';                  
                        setTimeout(() => {
                            window.location.href = this.state.linkUrl + '?status=true'+ returnparam + customfield;
                        }, 3000); 
                }
            }
            
            
        } catch (err) {
            this.setState({loading:false});
            console.log(err);           

        }
       
    } 

    getapikeyfromClientID = async (e) => {
        try { 
            const response = await fetch('https://dev-api.theloginhub.eu/api/v1/companies/info', {
                // mode:  'no-cors',
                method: 'GET',
                headers: {
                'client_id': this.getParams("client_id"),
                'Content-Type': 'application/json'                       
                }
            });
            if (!response.ok) {
                this.setState({ error: true });  
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            if (data.success === true) {
                this.setState({ apikey: data.data.apikey, companyName: data.data.name_firma, companyLogo: data.data.logo_image });  
                //console.log(data);
                if(this.getParams("user")) {
                    this.handleuserverification(data.data.apikey);
                }
            }           

            //console.log(data);
           // setJoke(data[0].joke);
                     
            
        } catch (err) {
            console.log(err);           

        }
        
    }
    
    socialVerification = async (servicename) => {        
       
        this.setState({loading: true}); 
        const pageurl = new URL(window.location.href);  
        const domainName = pageurl.protocol + '//' + pageurl.hostname; 
       // console.log(this.state.apikey);      
        var urlLink = 'https://dev-api.theloginhub.eu/api/v1/social_login';
        var  val = JSON.stringify({
            refer_url: domainName, 
            session_url : this.state.linkUrl,
            return_code: this.state.clientState  

        }); 
        let res = await fetch(urlLink, {
            method: "POST",
            headers: {                   
                'Content-Type': 'application/json',
                'apikey' : this.state.apikey,
                'socialname' : servicename
                
            },
            body: val,
        });
        let resCallback = await res.json();   
        //console.log(resCallback.data.url);
        if (resCallback.error === false) { 
            if(resCallback.data && Array.isArray(resCallback.data)) {                    
                var errorObj = [];
                resCallback.data.forEach(element => {
                    errorObj[element.field] = (errorObj[element.field])? errorObj[element.field] + ', ' + element.message: element.message
                });
                this.setState({ errorMessage: errorObj,loading:false});
            } else {
                this.setState({ errorMessage: resCallback.data, loading:false});
            }      
                // console.log('here');                              
                // this.setState({ successMessage: "", loading:false, errorMessage: resCallback.data }); 
        } 
        if (resCallback.success === true) { 
                this.setState({loading: true, successMessage: "Redirecting..." ,  errorMessage: ""});  
                //this.setState({loading: false,successMessage: "Redirecting..." ,  errorMessage: "",});
                setTimeout(() => {
                    window.location.href = resCallback.data.url;
                }, 3000);
        }

    }

    getQueryParams = () => {
        const queryString = window.location.search.slice(1);
        const params = {};
        queryString.split('&').forEach(param => {
            const [key, value] = param.split('=');
            if (key) {
                params[decodeURIComponent(key)] = decodeURIComponent(value || '');
            }
        });
        return params;
    };

    handleuserverification = async (apikey) => {
        this.setState({loading: true});
        var loginmethod =  this.getParams("login_method") ;
        var url = '';
        var postdata = '';
        const pageurl = new URL(window.location.href);  
        const domainName = pageurl.protocol + '//' + pageurl.hostname;
        try {
            if(loginmethod === 'sms') {
                postdata = JSON.stringify({
                    mobile:  this.getParams("user") ,                
                });
                url = 'https://dev-api.theloginhub.eu/api/v1/sms_login';
            } 
            if(loginmethod === 'email') {
                postdata = JSON.stringify({
                    email:  this.getParams("user"),  
                    method: this.state.emailOption,
                    refer_url: domainName,
                    redirect_uri : this.getParams("redirect_uri"),
                    return_code: this.getParams("return_code") 
                });
                url = 'https://dev-api.theloginhub.eu/api/v1/email-verification';
            }
            // console.log(url);
            // console.log(postdata);

            let res = await fetch(url, {
                method: "POST",
                headers: {                   
                    'Content-Type': 'application/json',
                    'apikey' : apikey,
                },
                body: postdata,
            });
            let resJson = await res.json();
            if (resJson.error === true) {
                if(resJson.data && Array.isArray(resJson.data)) {                    
                    var errorObj = [];
                    resJson.data.forEach(element => {
                        errorObj[element.field] = (errorObj[element.field])? errorObj[element.field] + ', ' + element.message: element.message
                    });
                    this.setState({ errorMessage: errorObj,loading:false});
                } else {
                    this.setState({ errorMessage: resJson.data.message, loading:false});
                }
            } 
            if (resJson.success === true) {
                let msg = resJson.data.message;
                             
                if(this.state.loginMethod === 'email' && this.state.emailOption === 'url') {
                    this.setState({  allhide: true});                                   
                }
                this.setState({ successMessage: msg ,  errorMessage: "", flag: "true", loading:false}); 
                 
            }
            
            
        } catch (err) {
            this.setState({loading:false});
            console.log(err);           

        }
    } 

    componentDidMount = async() => {  

       // const searchParams = new URLSearchParams(document.location.search);
        const searchParams =  this.getQueryParams();
       // this.setState({test : Object.entries(searchParams).length});
        
        if(Object.entries(searchParams).length > 0 ) {
            this.setState({checkParam : true});
        }
        if (searchParams && searchParams.client_id) {         
            this.setState({ clientId:  searchParams.client_id});  
            if(searchParams.user) {
                this.setState({ userName:  searchParams.user});                  
            }
            if(searchParams.login_method) {
                this.setState({ loginMethod:  searchParams.login_method});                 
            }
            if(searchParams.return_code) {
                this.setState({ clientState:  searchParams.return_code});  
            }
            if(searchParams.redirect_uri) {
                this.setState({ linkUrl:  searchParams.redirect_uri});  
            }
           this.getapikeyfromClientID();

        }
     
        if (searchParams && searchParams.code && !this.state.apiCalled) {
            const paramcode = searchParams.code;  
            //const checkCode = this.checkIsBase64(paramcode);
            if(paramcode) {
                //const decodeCode = this.decodeBase64(paramcode);
                fetch('https://dev-api.theloginhub.eu/api/v1/email/' + paramcode)
                .then((response) => response.json())
                .then((resJson) => { 
                   //console.log(resJson);                   
                    if (resJson.status === true) { 
                        var returnparam = '';
                        if(resJson.data.token) {
                            returnparam = '&token=' + resJson.data.token;
                        }
                        if(resJson.data.user) {
                            returnparam = '&user=' + resJson.data.user; 
                        }
                        this.setState({ successMessage: resJson.data.message, errorMessage: "", flag: "true", apiCalled: true});  
                        if(resJson.data.redirect_uri) {  
                            var customfield = (resJson.data.return_code) ? '&return_code=' + resJson.data.return_code : '';
                            setTimeout(() => {
                                window.location.href = resJson.data.redirect_uri + '?status=true' + returnparam + customfield;
                            }, 3000);                   
                        }    
                       
                    } else {
                        this.setState({ successMessage: "", errorMessage: resJson.data.message, flag: "true", apiCalled: true}); 
                        if(resJson.data.redirect_uri) { 
                            setTimeout(() => {
                                window.location.href = resJson.data.redirect_uri + '?status=false&user=' + resJson.data.user ;
                            }, 3000);                   
                        }  
                    } 
                    
                    // if (resJson.Status === 'OK' && resJson.success) {
                       
                    //     this.setState({ successMessage: "Success! You've been verified using "+ decodeCode.method , errorMessage: "", flag: "true", apiCalled: true});  
                    //     if(decodeCode.redirect_uri) {  
                    //         let customfield = (decodeCode.return_code) ? '&return_code=' + decodeCode.return_code : '';                       
                    //         let user = (decodeCode.user) ? '&user=' + decodeCode.user : '';                       
                    //         setTimeout(() => {
                    //             window.location.href = decodeCode.redirect_uri + '?status=true' + user + customfield;
                    //         }, 3000);                   
                    //     }    
                    // } else {
                    //     this.setState({ successMessage: "", errorMessage: resJson.data, flag: "true", apiCalled: true});  
                    // }        
                })
                .catch((err) => {
                    this.setState({ successMessage: "", errorMessage: err, flag: "true", apiCalled: true});  
                   // console.log(err); 
                }); 
            }           
            
        }

      
    }

   
    // componentWillUnmount() {
    //     // Clear the timeout to avoid memory leaks
    //     clearTimeout(this.timeoutId);
    //   }

    // handlePaste = (e) => {
    //     e.preventDefault();
    //     const pasteData = e.clipboardData.getData('text/plain');
    //     const pasteArray = pasteData.split('');
    //     const newCodes = [...this.state.codes];
    
    //     pasteArray.forEach((value, index) => {
    //       if (/^\d*$/.test(value)) {
    //         newCodes[index] = value;
    //       }
    //     });    
    //     this.setState({ codes: newCodes });
    //   };

    render() {        
      
         var gap = this.state.loginMethod === 'sms' ? 'gap' : '';        
         const numInputs = this.state.loginMethod === 'sms' ? 4 : 4;


       
        return (  
            <div className='container'> 
                {  

                    (!this.state.checkParam) ?
                    <Homelink />
                    : null                         
                }
                {  
                (this.state.checkParam) ? 
                    <div className='row'>
                        <div className='tlh_form_wrap'>                                   
                            <div id="container-scroller" className={(!this.state.clientId && !this.state.apikey) ? 'pt-5 pb-5': 'add_border pt-5 pb-5'}>                                
                                    <div className='logo_wrap d-flex justify-content-center' >
                                        {
                                            this.state.companyLogo ?
                                            <img src={this.state.companyLogo} width = {225} className ="tlh-logo" alt="logo" /> :
                                            null
                                        }                                
                                    </div>                            
                                    
                                    {
                                    (this.state.apiCalled ) ?
                                        <div>
                                            {
                                                this.state.successMessage ? <p className='alert alert-success text-center'>{this.state.successMessage}</p>
                                                : (this.state.errorMessage && !Array.isArray(this.state.errorMessage) ) ? <p className='alert alert-warning text-center'>{this.state.errorMessage}</p>
                                                : null
                                            }
                                        </div> : null
                                    }                           
                                    
                                    {
                                        (this.state.clientId && this.state.apikey) ?                                
                                            <div className="verification_form">
                                                {/* <p>Parameters: {this.getParams('id')}</p>  */}  
                                                {
                                                    this.state.loading === true ?
                                                        <div className="loading_wrap">
                                                            <img src={loadingImg} className ="loading_icon" alt="loading img" /> 
                                                            <span>Loading..</span>
                                                        </div>  
                                                    : null
                                                }                                              
                                                <div className="container">  
                                                        <div className="content_wrap">                                   
                                                        {
                                                            (this.state.flag === '') ?  
                                                            <h6 className="text-center pt-5 pb-2">CUSTOMER VERIFICATION</h6> :
                                                            <h6 className="text-center pt-5 pb-2">WE VERIFY YOU</h6>
                                                        }   
                                                        
                                                        {
                                                            this.state.successMessage ? <p className='alert alert-success text-center'>{this.state.successMessage}</p>
                                                            : (this.state.errorMessage && !Array.isArray(this.state.errorMessage) ) ? <p className='alert alert-warning text-center'>{this.state.errorMessage}</p>
                                                            : null
                                                        }
                                                        {
                                                            (this.state.flag === '') ?  
                                                            <form onSubmit={this.handleloginSubmit}>
                                                                {
                                                                    (this.state.loginMethod === 'sms') ?
                                                                        <div>
                                                                            <div className="form-group">
                                                                            
                                                                                <span className="phone_sign">+47</span>                              
                                                                                <input type="text" className="form-control" id="username"  value={this.state.userName} onChange={this.changeuserName} placeholder="Mobile*" />
                                                                                {this.state.errorMessage && <span className='text-center text text-danger text-small'>{this.state.errorMessage['mobile']}</span>}
                                            
                                                                            </div>
                                                                            <div className="text-center pt-3">
                                                                                <button type="submit" className="btn btn-primary login-btn">Login</button>
                                                                            </div>
                                                                        </div>
                                                                    : (this.state.loginMethod === 'email') ?
                                                                        <div>
                                                                            <div className="form-group">                                                                
                                                                                <input type="text" className="form-control email_field" id="username"  value={this.state.userName} onChange={this.changeuserName} placeholder="example@email.com*" />
                                                                                {this.state.errorMessage && <span className='text-center text text-danger text-small'>{this.state.errorMessage['email']}</span>}                            
                                                                            </div>
                                                                            <div className="form-group radio_btn">
                                                                                <label><input type="radio" className="input-radio off" name="email_method" value="url" checked={this.state.emailOption === "url"} onChange={this.onValueChange}/> Redirect Url</label>
                                                                                <label><input type="radio" className="input-radio on" name="email_method" value="code" checked={this.state.emailOption === "code"} onChange={this.onValueChange} /> Code</label>                    
                                                                            </div>
                                                                            <div className="text-center pt-3">
                                                                                <button type="submit" className="btn btn-primary login-btn">Login</button>
                                                                                { 
                                                                                (this.state.apikey === 'tc54m8auqgn7j3llo') ?
                                                                                <div className="social_wrap pt-3">
                                                                                    <p className="grey">Or verify with</p>
                                                                                    <span onClick={() => this.socialVerification('facebook')}><img src={Facebook}  width="40" alt="facebook logo" /></span>
                                                                                    <span onClick={() => this.socialVerification('google')}><img src={Google}  width="40" alt="Google logo" /></span> 
                                                                                </div> : null
                                                                                }
                                                                                
                                                                                
                                                                            </div>
                                                                        </div>
                                                                    : 
                                                                    <div className="mt-3 alert alert-danger text-center">Undefined method!!</div>
                                                                }
                                                            
                                                            </form>  :
                                                            (this.state.clickCount < 4 && !this.state.hide && !this.state.allhide) ?
                                                                <div>
                                                                    <div className="text-center trouble_wrap">
                                                                        <p>Haven't received a text message? <Link to="#" onClick={this.handleloginSubmit} >Get a new code</Link></p>
                                                                        
                                                                    </div>
                                                                    <form onSubmit={this.handlecodeSubmit} className="code_wrap">
                                                                        <p className="text-center">Enter confirmation code*:</p>
                                                                        <div className="form-group verifycode_wrap d-flex mb-1"> 
                                                                            {this.state.codes.slice(0, numInputs).map((value, index) => (
                                                                                <input
                                                                                    key={index}
                                                                                    className={"form-control single_field " + gap}
                                                                                    type="tel"
                                                                                    maxLength="1"
                                                                                    value={value}
                                                                                    onChange={(e) => this.handleInputChange(index, e)}
                                                                                    onPaste={this.handlePaste}
                                                                                    ref={this.inputRefs[index] || (this.inputRefs[index] = React.createRef())}
                                                                                    onKeyDown={(e) => this.handleKeyDown(index, e)}
                                                                                />
                                                                            ))}
                                                                        </div>                                                                                                                                                                                                                                                        
                                                                        <div className="text-center pt-3">
                                                                            <button type="submit" className="btn btn-primary login-btn">Verify</button>
                                                                        </div>
                                                                        <div className="text-center trouble_wrap pt-3">
                                                                            <p>Having trouble with the verification code? <Link to="mailto:contact@theloginhub.eu">Contact Us</Link></p>                                                                           
                                                                        </div>
                                                                    </form> 
                                                                </div>   
                                                            :
                                                            null
                                                        }
                                                    </div> 
                                                </div>
                                                {
                                                    (this.state.linkUrl) ? 
                                                    <div className="text-center pt-5">
                                                        <Link to={this.state.linkUrl}>Cancel</Link> 
                                                    </div> : null
                                                }  
                                                        
                                            </div> 
                                        :
                                        (this.state.error && !this.state.apikey) ? 
                                            <div className="mt-3 alert alert-danger text-center">Company not found!!</div>
                                        : null
                                    }
                                    {
                                        this.state.companyName ? 
                                            <p className="text-center pt-3 company_name">{this.state.companyName}</p> 
                                        : null
                                    }  
                               
                            </div> 
                        </div>
                       
                    </div>
                : null
                 }
              
          </div>  
                
                                    
        )
    }
}