import React, { Component } from "react";
import { Link } from 'react-router-dom';
import mainLogo from '../TLH-logo-small.png';

export class ApiInfo extends Component {
    render () {
        return (
            <div className='container'> 
                <div className="info_wrap mt-3 pt-2">             
                    <div className='logo_wrap d-flex justify-content-center' >
                        <img src={mainLogo} width = {150} className ="tlh-logo" alt="logo" />  
                    </div>
                    <div className="text-end">
                        <span><Link to="/">Home</Link></span>
                    </div>
                     <div className="mt-5 pt-5 pb-5 ps-5 pe-5 bg-light">
                        <h4 className="text-center pb-5">API Information:</h4> 
                        <div className="row">
                            <div className="col-md-7">
                                <div className="api-info">
                                    <h5>Mobile Login:</h5>
                                    <u></u> 
                                    <div>                           
                                        <p><strong>Method:</strong> <span>POST /api/v1/sms_login</span></p>
                                    </div>
                                    <div>
                                        <p><strong>Header:</strong> <br></br>                          
                                        APIKEY: (we will provide) <br></br>
                                        Content-Type: application/json
                                        </p>
                                    </div>
                                    <div>
                                        <strong>Body:</strong> 
    <pre>
        {`
        {
            mobile: mobilenumber                                       
        }
        `} 
    </pre> 
                                                             
                                    </div>  
                                </div>  
                                <div className="api-info">
                                    <h5>Mobile Verification:</h5>
                                    <u></u> 
                                    <div>                           
                                        <p><strong>Method:</strong> <span>POST /api/v1/sms_login/activated</span></p>
                                    </div>
                                    <div>
                                        <p><strong>Header:</strong> <br></br> 
                                        Content-Type: application/json
                                        </p>
                                    </div>
                                    <div>
                                        <strong>Body:</strong> 
    <pre>
        {`
        {
            mobile: "",
            code : ""                                           
        }
        `} 
    </pre> 
                                                             
                                    </div>  
                                </div>                       
                            </div>
                            <div className="col-md-5">
                                <div className="p-3 mb-2 bg-dark text-white">                                    
                                    <p>Success: 200</p>
    <pre>
        {`
        {
            success: true,
            Status: 'OK',
            data: {
                message
            }
        }
        `} 
    </pre>                                                         
                                    <p>Error: 400</p>
    <pre>
        {`
        {
            error: false,
            data: message
        }`
        }
    </pre>
                                </div>
                            </div>
                        </div>                  
                    </div>
                </div>  
             </div>           
               
        )
    }
    
}
