import React, { Component } from "react";
import mainLogo from '../TLH-logo-small.png';
import { Link } from 'react-router-dom';
import Step1 from '../step1.png';
import Step2 from '../step2.png';
import Step3 from '../step3.png';
import Video from '../demo.mp4';


export class GuidePage extends Component {

    render () {
        return (
            <div className='container'> 
                <div className="info_wrap mt-3 pt-2">             
                    <div className='logo_wrap d-flex justify-content-center' >
                        <img src={mainLogo} width = {150} className ="tlh-logo" alt="logo" />  
                    </div>
                    <div className="text-end">
                        <span><Link to="/">Home</Link></span>
                    </div>
                    <div className="User Guide">
                        <h3>User Guide</h3>
                        <p><strong>example Link:</strong> https://customer-api.theloginhub.eu?client_id=3hg6aeibgasewu7z236hgsmj3k58zi&login_method=sms&redirect_uri=https://theloginhub.eu</p>
                        <div className="params_wrap">
                            <h4><strong>URL parameters</strong></h4>
                            <p><strong>client_id</strong> = Unique company ID ( will provide)</p>
                            <p><strong>login_method</strong> = sms/email (select one)</p>
                            <p><strong>redirect_uri</strong> = Customer Site Link</p>
                            <p><strong>return_code</strong> = Customer fields</p>
                            <p><strong>user</strong> = mobile/email</p>
                        </div>                       
                        <div className="row">
                            <div className="col-md-3">
                                <div className="text_wrap text-center">Customer website<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;-------&gt;</span></div>
                            </div>
                            <div className="col-12 col-md-3">
                                <img src={Step1} className ="step_img" alt="step1 img" /> 
                            </div>
                            <div className="col-12 col-md-3">
                                <img src={Step2} className ="step_img" alt="step2 img" /> 
                            </div>
                            <div className="col-12 col-md-3">
                                <img src={Step3} className ="step_img" alt="step3 img" /> 
                            </div>
                            <div className="col-12">
                                <video controls width="100%" height="350">
                                        <source type="video/mp4" src={Video} />
                                </video>
                            </div>
                        </div>
                    </div> 
                </div>  
             </div>           
               
        )
    }
}