import React from 'react';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
// import { SmsVerification } from './modules/SmsVerification';
import { HomePage } from './modules/HomePage';
import { ApiInfo } from './modules/ApiInfo';
import { GuidePage } from './modules/GuidePage';
import { Demopage } from './modules/demo';




function App() {
  return (
    <BrowserRouter>
    <div className="App" id="outer-container">
      <Routes>          
        <Route path='/' element={<HomePage />}></Route> 
        <Route path='/userguide' element={<GuidePage />}></Route> 
        <Route path='/apiinfo' element={<ApiInfo />}></Route>
        <Route path='/demo' element={<Demopage />}></Route>
        {/* <Route path='/smsverification' element={<SmsVerification />}></Route>           */}
      </Routes>      
    </div>
   
  </BrowserRouter>
  );
}

export default App;